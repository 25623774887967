import React from "react"
import { StaticQuery, graphql } from "gatsby"
import TermsPageComponent from "../page-components/TermsPageComponent"

const termsAndConditionsQuery = graphql`
  query {
    page: markdownRemark(
      fields: { slug: { eq: "/pages/terms-and-conditions/" } }
    ) {
      frontmatter {
        seoTitle
        seoDescription
        seoImage {
          childImageSharp {
            fixed(width: 480, quality: 92) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        text
      }
    }
  }
`

const TermsAndConditionsPage = props => (
  <StaticQuery
    query={termsAndConditionsQuery}
    render={data => {
      const {
        page: {
          frontmatter: { seoTitle, seoDescription, seoImage, text },
        },
        features,
      } = data

      return (
        <TermsPageComponent
          features={features}
          seoTitle={seoTitle}
          seoDescription={seoDescription}
          seoImage={
            (seoImage &&
              seoImage.childImageSharp &&
              seoImage.childImageSharp.fixed &&
              seoImage.childImageSharp.fixed.src) ||
            ""
          }
          text={text}
          url="https://cloudware.pt/termos-e-condicoes"
          {...props}
        />
      )
    }}
  />
)

export default TermsAndConditionsPage
